/* footer */
footer {
	background-color: #7d7e7b;
	padding-top: 35px;
	color: #fff;
	font-size: 16px;
	h6 {
		color: #fff;
		margin-top: 0;
	}
	ul {
		@include ul-default;
		li.active a {
			text-decoration: underline;
		}
	}
	a {
		display: block;
		color: #fff;
		&:hover {
			color: #fff;
		}
	}
	.footer__logo {
		margin: 25px 0;
	}
	.footer__nav {
		ul li {
			margin-bottom: 5px;
		}
	}
	.footer__address, .footer__contacts {
		ul li {
			padding-left: 0;
			margin-bottom: 20px;
			&:after {
				color: #d40005;
				left: -20px;
			}
		}
	}
	.scheme {
		display: table;
		border-bottom: 1px dashed #fff;
		&:hover {
			text-decoration: none;
			border-bottom: 1px dashed transparent;
		}
	}
	.container-fluid {
		background-color: #d40005;
		padding: 10px 0;
		margin-top: 35px;
		p {
			margin: 0;
			@include inline-block;
		}
		.artmedia {
			width: 260px;
			position: relative;
			float: right;
			padding-right: 120px;
			box-sizing: border-box;
			color: #fff!important;
			img {
				position: absolute;
				right: 0;
				top: -2px;
			}
		}
	}
	@media screen and (max-width: 991px) {
		padding-top: 15px;
		.footer__logo {
			margin-top: 0;
		}
		.footer__nav {
			margin-bottom: 15px;
		}
		.footer__address, .footer__contacts {
			margin-bottom: 15px;
			ul li {
				padding-left: 25px;
				margin-bottom: 15px;
				&:after {
					left: 0;
				}
			}
		}
		.container-fluid {
			margin-top: 10px;
		}
	}

	@media screen and (max-width: 580px) {
		.col-sm-6 {
			padding: 0;
		}
		.container-fluid {
			p {
				display: table;
				text-align: center;
				margin: 0 auto;
			}
			.artmedia {
				text-align: left;
				float: none;
				margin: 10px auto 0;
			}
		}
	}
}
/* footer */