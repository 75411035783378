/* page content */
.page {
	padding-bottom: 90px;
	.news1, .news {
		.date {
			color: #d30005;
		}
		a {
			text-decoration: underline;
			&:hover {
				color: #d30005;
				text-decoration: none;
			}
		}
	}
	.page_nav {
		.active {
			color: #d30005;
		}
	}
	h1 {
		font-size: 36px;
	}
	a {
		color: #414141;
		transition: 300ms;
		&:active, &:focus {
			text-decoration: none;
		}
		&:hover {
			color: #d30005;
			text-decoration: none;
		}
	}
	.page--box {
		text-align: center;
		padding: 15px;
		border: 2px solid #c00000;
		margin: 0 auto;
		p {
			margin: 5px 0;
		}
		a {
			display: block;
			margin: 2px 0;
		}
	}
	@media screen and (max-width: 1170px) {
		font-size: 16px;
		padding-bottom: 50px;
		#photos-list td img {
			width: 100%;
			height: auto;
		}
		h1 {
			font-size: 30px;
		}
	}
	@media screen and (max-width: 991px) {
		padding-bottom: 25px;
		h1 {
			font-size: 26px;
		}
	}
	@media screen and (max-width: 767px) {
		h1 {
			font-size: 20px;
		}
	}
	@media screen and (max-width: 480px) {
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}

/* page banner */
.banner {
	position: relative;
	overflow: hidden;
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,0.3);
	}
	img {
		display: block;
		width: 100%;
	}
	.container {
		padding: 0 15px;
		position: absolute;
		left: 0;
		right: 0;
		top: 200px;
		z-index: 1;
		h1 {
			font-size: 30px;
			color: #fff;
			margin: 0;
			line-height: 1;
			text-align: left;
		}
	}
	@media screen and (max-width: 991px) {
		.container {
			bottom: auto;
			top: 25px;
			h1 {
				font-size: 28px;
			}
		}
	}
	@media screen and (max-width: 680px) {
		.container {
			min-height: 175px;
			position: relative;
			top: 0;
			padding: 15px 15px;
			h1 {
				font-size: 22px;
			}
		}
		img {
			height: 100%;
			width: auto;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}
/* page banner */

/* breadcrumbs */
.breadcrumbs {
	font-size: 14px;
	margin: 15px 0px 35px;
	ul {
		@include ul-default;
		li {
			padding: 5px 0px;
			color: #d40005;
			@include inline-block;
			a {
				font-size: 14px;
				text-decoration: none;
			}
			&:after {
				content: '';
				margin: 6px 2px 0px 7px;
				width: 10px;
				height: 10px;
				background: url(../img/next.svg) no-repeat center center;
				background-size: contain;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
	@media screen and (max-width: 991px) {
		margin: 15px 0;
		
	}
}
/* breadcrumbs */

/* page about */
.about--page {
	.about .about__block .about__block__col {
		padding-top: 50px;
	}
}
/* page about */

/* page catalog */
.cat {
	h2 {
		font-size: 30px;
		color: #7d7e7b;
		text-transform: inherit;
		text-align: left;
	}
	.cat__photos {
		font-size: 0;
		.cat__photos__col {
			width: 33.33%;
			overflow: hidden;
			margin: 15px 0;
			@include inline-block;
			img {
				display: block;
				width: 100%;
			}
		}
	}
}
/* page catalog */

/* page unit */
.cat__for {
	width: 100%;
	position: relative;
	border: 1px solid #d4d4d4;
	overflow: hidden;
	&:after {
		content: '\f00e';
		font-family: 'fontAwesome';
		font-size: 32px;
		color: #d40005;
		position: absolute;
		right: 18px;
		bottom: 18px;
	}
	.slick-slide {
		outline: none;
		img {
			display: block;
			width: 100%;
		}
	}
}

.cat__nav {
	margin: 30px -15px;
	padding: 0px 0px;
	.slick-slide {
		outline: none;
		overflow: hidden;
		margin: 0 15px;
		border: 1px solid #cccccc;
		transition: 300ms;
		img {
			display: block;
			width: 100%;	
		}
		&:hover, &.slick-current {
			border: 1px solid #707070;
		}
	}
	.slick-arrow {
		height: 30px;
		z-index: 1;
		&:before {
			display: none;
		}
		&:after {
			font-family: 'fontAwesome';
			font-size: 25px;
			color: #7d7e7b;
			z-index: 1;
			transition: 400ms;
		}
		&.slick-prev {
			left: 20px;
			&:after {
				content: '\f053';
			}
		}
		&.slick-next {
			right: 20px;
			&:after {
				content: '\f054';
			}
		}
		&:hover {
			&:after {
				color: #d40005;
			}
		}
	}
}
.table--cat {
	margin-top: 15px;
	width: 100%;
	border-collapse: collapse;
	td {
		padding: 5px 10px 5px 0px;
	}
}
.download {
	display: table;
	position: relative;
	padding-right: 25px;
	font-family: $bold;
	text-decoration: none!important;
	margin: 25px 0;
	&:after {
		content: '\f019';
		font-family: 'fontAwesome';
		font-size: 18px;
		color: #d40005;
		position: absolute;
		top: 1px;
		right: 0;
	}
}
.characters {
	@media screen and (max-width: 480px) {
		table td {
			display: block;
			width: 100%;
			white-space: normal!important;
		}
	}
}
/* page unit */

/* page contacts */
.contacts__info {
	text-align: center;
	.contacts__info__col {
		min-height: 125px;
		position: relative;
		padding: 15px 0px;
		margin-bottom: 50px;
		&:after {
			content: '';
			width: 1px;
			background-color: #d7d7d7;
			position: absolute;
			right: -15px;
			top: 0;
			bottom: 0;
		}
		ul {
			@include ul-default;
			li {
				display: table;
				margin: 5px auto;
				text-align: left;
				&:after {
					color: #d40005;
				}
				a {
					text-decoration: none;
				}
			}
		}
	}
	.col-sm-4 {
		&:nth-child(1) {
			.contacts__info__col {
				&:before {
					content: '';
					width: 1px;
					background-color: #d7d7d7;
					position: absolute;
					left: 0px;
					top: 0;
					bottom: 0;
				}
			}
		}
		&:nth-child(3) {
			.contacts__info__col {
				&:after {
					right: 0;
				}
			}
		}
	}
	@media screen and (max-width: 767px) {
		.contacts__info__col { 
			min-height: auto;
			margin: 0px 0;
			&:before, &:after {
				display: none!important;
			}
		}
	}
}

.contacts__block {
	font-size: 0;
	background-color: #eeeeee;
	.contacts__block__col {
		height: 630px;
		width: 50%;
		font-size: 16px;
		overflow: hidden;
		@include inline-block;
		.map {
			> ymaps {
				width: 100%!important;
				height: 630px!important;
			}
			.ymaps-2-1-64-map {
				height: 100%!important;
			}
		}
		.form {
			width: 570px;
			float: right;
			padding: 100px 50px 0px 0px;
			box-sizing: border-box;
			h4 {
				text-transform: uppercase;
				margin-bottom: 20px;
			}
			.row {
				.col-md-6 {
					&:first-child {
						padding-right: 10px;
					}
					&:last-child {
						padding-left: 10px;
					}
				}
			}
			input[type="text"],
			input[type="email"],
			textarea {
				display: block;
				width: 100%;
				padding: 8px 15px;
				margin-bottom: 15px;
				border: 1px solid #d7d7d7;
			}
			.g-recaptcha {
				margin-bottom: 10px;
			}
			.btn {
				float: right;
			}
		}
	}
	@media screen and (max-width: 1199px) {
		.contacts__block__col {
			.form {
				width: 100%;
				padding: 100px 25px 0px 25px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.contacts__block__col {
			display: block;
			height: auto;
			width: 100%;
			.form {
				padding: 15px;
				.g-recaptcha {
					transform: scale(0.9);
				}
			}
			.map {
				> ymaps {
					height: 300px!important;
				}
			}
		}
	}
	@media screen and (max-width: 767px) {
		.contacts__block__col {
			.form .row {
				.col-md-6 {
					&:first-child {
						padding-right: 15px;
					}
					&:last-child {
						padding-left: 15px;
					}
				}
			}
		}
	}
}
/* page contacts */

/* page pagination */
.pagination {
	ul {
		@include ul-default;
		li {
			margin-bottom: 10px;
			@include inline-block;
			a {
				text-decoration: none;
				width: 40px;
				height: 40px;
				display: block;
				border: 1px solid transparent;
				font-size: 16px;
				text-align: center;
				box-sizing: border-box;
				padding: 10px 2px 0px;
				outline: none;
				color: #222222;
				border: 1px solid #d40005;
				line-height: 1;
				&:hover {
					background-color: #d40005;
					border: 1px solid #d40005;
					color: #fff;
				}
			}
			&.active {
				a {
					background-color: #d40005;
					border: 1px solid #d40005;
					color: #fff;
				}
			}
			&.prev, &.next {
				a {
					position: relative;
					border: 1px solid transparent;
					background-color: transparent!important;
					transition: 0ms;
					&:hover {
						border: 1px solid transparent;
						background-color: transparent!important;
						color: #d40005;
					}
					&:after {
						display: block;
						font-family: 'fontAwesome';
						font-size: 22px;
						position: absolute;
						left: 0;
						right: 0;
						top: 50%;
						margin: -12px auto;
						transition: 350ms;
					}
				}
			}	
			&.prev a {
				&:after {
					content:'\f104';
				} 
			}
			&.next a {
				&:after {
					content: '\f105';
				} 
			}
		}
	}
}
/* page pagination */

.arrow--up {
	position: fixed;
	right: 35px;
	bottom: 45px;
	font-size: 30px;
	opacity: 0;
	&:hover {
		text-decoration: none;
	}
	&:after {
		content: '\f077';
		font-family: 'fontAwesome';
		color: #d40005;
	}
	@media screen and (max-width: 1280px) {
		display: none;
	}
}

/* modal */
.modal {
	.modal-content {
		max-width: 420px;
		border-radius: 0px;
		margin: 0 auto;
		h4 {
			margin: 0!important;
		}
		.close {
			width: 25px;
			height: 25px;
			font-family: 'FontAwesome';
			font-size: 25px;
			position: absolute;
			right: 10px;
			top: 14px;
			opacity: 1;
			outline: none;
			&:after {
				content: '\f00d';
			}
			&:hover {
				opacity: 0.7;
			}
		}
		form {
			display: block;
			margin-top: 15px;
			input[type="text"],
			input[type="email"],
			textarea {
				display: block;
				width: 100%;
				padding: 10px 15px;
				margin-bottom: 10px;
				box-sizing: border-box;
				border: 1px solid #d7d7d7;
			}
		}
		.btn {
			background-color: #ff0000;
			width: 100%!important;
		}
		.map {
			margin-top: 15px;
		}
	}
	&#modal--map {
		@media screen and (min-width: 768px) {
			.modal-dialog {
				width: 750px;
			}
			.modal-content {
				max-width: 100%;
			}
		}
		@media screen and (max-width: 768px) {
			.modal-content {
				max-width: 100%;
				form {
					.g-recaptcha {
						transform: scale(0.9);
					}
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		h4 {
			font-size: 16px;
		}
	}
	
}
/* modal */

.call-btn {
	position: fixed;
	bottom: 100px;
	right: 35px;
	display: block;
	background-color: #d40005;
	font-family: 'FontAwesome';
	border-radius: 100%;
	text-decoration: none!important;
	width: 80px;
	height: 80px;
	text-align: center;
	padding: 25px 5px 5px 5px;
	box-shadow: 0px 0px 35px rgba(0,0,0,0.7);
	outline: none;
	z-index: 2;
	&:hover {
		animation-name: phone;
	  	animation-iteration-count: 1;
	  	animation-duration: 0.7s;
	}
	i {
		display: block;
		font-size: 34px;
		color: #fff;
	}
	&:after,&:before  {
		content: '';
		width: 90%;
		height: 90%;
		background-color: transparent;
		border: 1px solid #fff;
		position: absolute;
		top: 5%;
		left: 5%;
		border-radius: 100%;
		animation-iteration-count: infinite;
	    animation-duration: 1.2s;
	    animation-name: ring;
	}
	&:after {
		animation-delay: 0.6s;
	}
	&:before {
		animation-delay: 0.8s;
	}
	@media screen and (max-width: 991px) {
		width: 50px;
		height: 50px;
		padding-top: 13px;
		right: 15px;
		bottom: 30px;
		i {
			font-size: 24px;
		}
	}
}
@keyframes ring {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0.1;
    transform: scale(1.7);
  }
}
@keyframes phone {
  20% {
   transform: rotate(-30deg); 
  }

  80% {
    transform: rotate(390deg)
  }

  100% {
    transform: rotate(360deg);
  }
}

/* page content */