/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.main--slider {
	position: relative;
	.slider {
		margin-bottom: 0px;
		.slick-slide {
			overflow: hidden;
			position: relative;
			outline: none;
			&:after {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background-color: rgba(0,0,0,0.5);
			}
			img {
				display: block;
				width: 100%;
				height: auto;
				transition: all 1.5s cubic-bezier(0.7, 0, 0.3, 1);
      			transform: scale(1.2);
			}
			&.slick-active {
				img {
					transform: scale(1);
				}
			}
			.slider__block {
				position: absolute;
				left: 0;
				right: 0;
				top: 50%;
				text-align: center;
				color: #fff;
				margin: 0 auto;
				z-index: 1;
				.slider__block__title {
					display: block;
					font-family: $bold;
					font-size: 46px;
				}
				.slider__block__slogan {
					display: block;
					font-size: 36px;
				}
			}
		}
	}
	.slider--nav {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 50px;
		z-index: 1;
		.slider--nav__list {
			display: table;
			height: 100%;
			.slick-dots {
				display: table-cell;
				height: 100%;
				vertical-align: middle;
				position: relative;
				bottom: 0;
				top: 0;
				z-index: 1;
				li {
					display: block;
					margin: 5px 0px;
					height: auto;
					width: auto;
					button {
						width: 14px;
						height: 14px;
						background-color: #ffffff;
						border: 2px solid #d30005;
						box-sizing: border-box;
						transition: 300ms;
						&:before {
							display: none;
						}
						&:hover {
							background-color: #d30005;
						}
					}
					&.slick-active {
						button {
							background-color: #d30005;
						}
					}
				}
			}
		}
	}
	@media screen and (max-width: 1199px) {
		.slider {
			.slick-slide .slider__block {
				padding: 0 60px;
				margin-top: -80px;
			}
		}
		.slider--nav {
			left: 20px;
		}
	}
	@media screen and (max-width: 991px) {
		.slider .slick-slide .slider__block {
			.slider__block__title {
				font-size: 38px;
			}
			.slider__block__slogan {
				font-size: 30px;
				line-height: 1.2;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.slider .slick-slide {
			> img {
				height: 340px;
				width: auto;
			}
			.slider__block {
				margin-top: -50px;
				.slider__block__title {
					font-size: 24px;
				}
				.slider__block__slogan {
					font-size: 20px;
				}
			}
		}
	}
	@media screen and (max-width: 480px) {
		.slider .slick-slide {
			> img {
				height: 280px;
			}
			.slider__block {
				padding: 0 35px;
			}
		}
		.slider--nav {
			left: 10px;
		}
	}
	@media screen and (max-width: 380px) {
		.slider .slick-slide {
			> img {
				margin-left: -140px;
			}
		}
	}
}
/* main slider */

/* main directions */
.directions {
	.directions__col {
		display: block;
		position: relative;
		overflow: hidden;
		margin-bottom: 25px;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0,0,0,0.3);
		}
		img {
			display: block;
			width: 100%;
			filter: grayscale(0%);
			transition: 400ms;
		}
		.directions__col__title, .directions__col__info {
			width: 100%;
			position: absolute;
			right: 0;
			left: 0;
			top: 0;
			bottom: 0;
			font-family: $bold;
			font-size: 18px;
			color: #fff;
			line-height: 1.2;
			transition: 400ms;
			z-index: 1;
			span {
				display: table;
				width: 100%;
				height: 100%;
				padding: 15px;
				text-align: center;
				h4 {
					display: table-cell;
					vertical-align: middle;
					text-transform: uppercase;
					font-size: 18px;
					color: #fff;
					margin: 0;
				}
				p {
					display: table-cell;
					vertical-align: middle;
					margin: 0;
				}
			}
		}
		.directions__col__info {
			opacity: 0;
		}
		&:hover {
			img {
				filter: grayscale(100%);
				transform: scale(1.1);
			}
		}
	}
	&.directions--inside {
		.directions__col {
			margin-bottom: 30px;
			&:hover {
				.directions__col__title {
					opacity: 0;
				}
				.directions__col__info {
					opacity: 1;
				}
			}
		}
	}
	&.directions--all {
		.directions__col {
			margin-bottom: 15px;
		}
	}
	@media screen and (max-width: 991px) {
		.directions__col {
			.directions__col__title, .directions__col__info  {
				font-size: 12px;
				span h4 {
					font-size: 14px;
				}
			}
		}
	}
	@media screen and (max-width: 767px) {
		.directions__col {
			margin: 15px 0;
		}
		&.directions--inside {
			.directions__col {
				margin: 0 0 15px 0;
			}
		}
	}
}
.main {
	.directions .row {
		margin: 0;
		.col-sm-4 {
			padding: 0;
		}
	}
}
/* main directions */

/* main about */
.about {
	.about__block {
		font-size: 0;
		.about__block__col {
			width: 50%;
			font-size: 16px;
			box-sizing: border-box;
			@include inline-block;
			> div {
				width: 570px;
				box-sizing: border-box;
			}
		}
		img {
			width: 50%;
			@include inline-block;
		}
		&.right {
			.about__block__col > div {
				float: right;
				padding-right: 25px;
			}
		}
		&.left {
			.about__block__col > div {
				float: left;
				padding-left: 25px;
				h2 {
					text-align: left;
				}
			}
		}
	}
	h1 {
		margin-top: 0;
		color: #d30005;
		text-align: left;
	}
	h2 {
		color: #d30005;
	}
	h3 {
		color: #7d7e7b;
		margin-bottom: 20px;
	}
	@media screen and (max-width: 1199px) {
		.about__block {
			.about__block__col {
				> div {
					width: 100%; 
					padding: 0 15px!important;
					float: none!important;
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		.about__block {
			.about__block__col {
				padding-top: 25px!important;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.about__block {
			.about__block__col {
				display: block;
				width: 100%;
				padding-top: 0px!important;
				margin-bottom: 15px;
			}
			img {
				display: block;
				width: 100%;
				float: none;
			}
		}
	}
}

/* main about */

/* main partners */
.partners {
	.container {
		padding-bottom: 0px;
	}
	.partners__slider {
		padding: 0 35px;
		.slick-slide {
			outline: none;
			margin: 0px 15px;
			img {
				max-width: 100%;
				margin: 0 auto;
			}
			a {
				pointer-events: none;
			}
		}
		.slick-arrow {
			width: 30px;
			height: 45px;
			margin-top: -20px;
			background: url(../img/next.svg) no-repeat center center;
			background-size: contain;
			transition: 300ms;
			opacity: .7;
			&:before, &:after {
				display: none
			}
			&.slick-prev {
				transform: rotate(-180deg);
				left: 0;
			}
			&.slick-next {
				transform: rotate(0deg);
				right: 0;
			}
			&:hover {
				opacity: 1;
			}
		}
	}
}
/* main partners */

/* main news */
.news {
	.news__col {
		display: block;
		.news__col__img {
			position: relative;
			overflow: hidden;
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: rgba(0,0,0,0.5);
				transition: 400ms;
			}
			img {
				display: block;
				width: 100%;
				transition: 400ms;
			}
			.date {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				padding: 12px;
				font-family: $bold;
				color: #fff;
				background-color: #d40005;
				line-height: 1;
				z-index: 1;
			}
		}
		.news__col__info {
			padding: 15px 40px;
			box-sizing: border-box;
			border: 1px solid #e1e1e1;
			h5 {
				min-height: 38px;
				margin: 0 0 20px 0;
			}
			p {
				min-height: 80px;
			}
		}
		&:hover {
			text-decoration: none;
			color: #414141;
			.news__col__img {
				&:after {
					opacity: 0;
				}
				img {
					transform: scale(1.1);
				}
			}
		}
	}
	.btn {
		margin: 50px auto 25px;
	}
	@media screen and (max-width: 767px) {
		.news__col {
			margin-bottom: 15px;
			.news__col__info {
				padding: 15px;
			}
		}
		.btn {
			margin: 15px auto!important;
		}
	}
}
/* main news */

/* main content */