/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

@font-face {
    font-family: 'open_sansitalic';
    src: url('../fonts/opensans-italic-webfont.eot');
    src: url('../fonts/opensans-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-italic-webfont.woff2') format('woff2'),
         url('../fonts/opensans-italic-webfont.woff') format('woff'),
         url('../fonts/opensans-italic-webfont.ttf') format('truetype'),
         url('../fonts/opensans-italic-webfont.svg#open_sansitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'open_sanslight';
    src: url('../fonts/opensans-light-webfont.eot');
    src: url('../fonts/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-light-webfont.woff2') format('woff2'),
         url('../fonts/opensans-light-webfont.woff') format('woff'),
         url('../fonts/opensans-light-webfont.ttf') format('truetype'),
         url('../fonts/opensans-light-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'open_sansregular';
    src: url('../fonts/opensans-regular-webfont.eot');
    src: url('../fonts/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-regular-webfont.woff2') format('woff2'),
         url('../fonts/opensans-regular-webfont.woff') format('woff'),
         url('../fonts/opensans-regular-webfont.ttf') format('truetype'),
         url('../fonts/opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'open_sansbold';
    src: url('../fonts/open-sans-bold-webfont.eot');
    src: url('../fonts/open-sans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/open-sans-bold-webfont.woff2') format('woff2'),
         url('../fonts/open-sans-bold-webfont.woff') format('woff'),
         url('../fonts/open-sans-bold-webfont.ttf') format('truetype'),
         url('../fonts/open-sans-bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

$regular: 'open_sansregular';
$bold: 'open_sansbold';
$light: 'open_sanslight';
$italic: 'open_sansitalic';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

body {
	display: flex;
	height: 100vh;
	position: relative;
	flex-direction: column;
	font-family: $regular;
	color: #414141;
	margin: 0;
	@media screen and (max-width: 1199px) {
		padding-top: 108px;
	}
	@media screen and (max-width: 768px) {
		padding-top: 79px;
	}
}

h1 {
	font-family: $bold;
	font-size: 46px;
	text-transform: uppercase;
	text-align: center;
	color: #414141;
	line-height: 1;
	margin-bottom: 35px;
	@media screen and (max-width: 991px) {
		font-size: 32px;
		margin-bottom: 25px;
	}
	@media screen and (max-width: 768px) {
		font-size: 26px;
		margin-bottom: 20px;
	}
}
h3 {
	font-family: $bold;
	font-size: 24px;
	color: #414141;
	text-transform: uppercase;
	@media screen and (max-width: 991px) {
		font-size: 22px;
	}
	@media screen and (max-width: 768px) {
		font-size: 20px;
	}
}
h4 {
	font-family: $bold;
	font-size: 20px;
	color: #414141;
	@media screen and (max-width: 991px) {
		font-size: 20px;
	}
}
h5 {
	font-family: $bold;
	font-size: 18px;
	color: #414141;
}
h6 {
	font-family: $bold;
	font-size: 16px;
	text-transform: uppercase;
	color: #414141;
}
a {
	color: #414141;
	transition: 350ms;
	text-decoration: none;
	&:focus {
		color: #414141;
		text-decoration: none;
		outline: none;
	}
	&:hover {
		outline: none;
		color: #d30005;
		text-decoration: underline;
	}
}
input, textarea, button, select {
	border-radius: 0px;
	-webkit-appearance: none;
}
.main, .page {
	position: relative;
	flex: 1 0 auto;
	font-size: 16px;
	textarea, input {
		background-color: #fff;
		font-family: $regular;
		font-size: 16px;
		padding: 5px 15px;
		border: 1px solid #d7d7d7;
	}
}
.main {
	.container {
		padding-top: 60px;
		padding-bottom: 60px;
		@media screen and (max-width: 1170px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
	h2 {
		font-family: $bold;
		font-size: 40px;
		color: #414141;
		margin: 0 0px 30px;	
		text-align: center;
		a {
			font-family: $bold;
			font-size: 36px!important;
			color: #414141;
			&:hover {
				text-decoration: none;
			}
		}
		@media screen and (max-width: 991px) {
			font-size: 30px;
			margin-bottom: 20px;
			a {
				font-size: 30px!important;
			}
		}
		@media screen and (max-width: 768px) {
			font-size: 24px;
			margin-bottom: 15px;
			a {
				font-size: 24px!important;
			}
		}
	}
}
.btn {
	display: table;
	width: auto!important;
	background-color: #d40005!important;
	font-family: $bold!important;
	font-size: 16px;
	color: #fff;
	cursor: pointer;
	border-radius: 0px;
	border: 2px solid #d40005;
	padding: 8px 40px!important;
	transition: 400ms;
	&:focus, &:active {
		background-color: #d40005!important;
		color: #fff;
	}
	&:hover, &.active {
		background-color: #fff!important;
		color: #d40005!important;
	}	
	@media screen and (max-width: 768px) {
		font-size: 16px;
	}
}

.check--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		font-size: 16px;
		padding-left: 25px;
		margin: 10px 0;
		&:before {
			content: '\f00c';
			position: absolute;
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #d30005;
			left: 0;
			top: 0px;
		}
	}
}

.square--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		font-size: 16px;
		padding-left: 20px;
		margin: 10px 0;
		&:before {
			content: '';
			width: 7px;
			height: 7px;
			position: absolute;
			font-family: 'fontAwesome';
			font-size: 18px;
			background-color: #d40005;
			left: 0;
			top: 8px;
		}
	}
}

.box--text {
	display: block;
	padding: 10px 0px 10px 50px;
	position: relative;
	margin: 15px 0;
	&:after {
		content: '';
		width: 3px;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 25px;
		background-color: #d40005;
	}
	p {
		margin-bottom: 0;
	}
	@media screen and (max-width: 767px) {
		padding-left: 30px;
		&:after {
			left: 10px;
		}
	}
}

.col--location,
.col--phone,
.col--email {
	position: relative;
	padding-left: 25px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 20px;
		position: absolute;
		top: 2px;
		left: 0;
	}
}
.col--location:after {
	content: '\f041';
}
.col--phone:after {
	content: '\f095';
}
.col--email:after {
	content: '\f0e0';
	font-size: 16px;
	top: 3px;
}

/* header */
header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	padding-top: 15px;
	color: #fff;
	z-index: 9;
	ul {
		@include ul-default;
	}
	a:hover {
		text-decoration: none;
	}

	.down {
		position: relative;
		margin-top: 15px;
	}

	/* header logo */
	.logo {
		@include inline-block;
		img {
			@include inline-block;
		}
		.logo__slogan {
			@include inline-block;
			font-family: $bold;
			font-size: 26px;
			margin: 28px 0px 0px 15px;
			color: #fff;
		}
	}
	/* header logo */

	.header--col {
		float: right;
		@include inline-block;
		.btn {
			font-family: $regular!important;
			padding: 8px 30px!important;
		}
	}

	/* header phones */
	.phones {
		position: relative;
		padding-left: 25px;
		margin-top: 10px;
		&:after {
			content: '\f095';
			font-family: 'fontAwesome';
			font-size: 24px;
			color: #d30005;
			position: absolute;
			top: 0;
			left: 0;
		}
		ul li a {
			font-size: 18px;
			color: #fff;
		}
	}
	/* header phones */

	

	/* header nav */
	.nav {
		background-color: #7d7e7b;
		padding-left: 15px;
		ul li {
			line-height: 1;
			@include inline-block;
			a {
				display: block;
				font-size: 17px;
				text-transform: uppercase;
				padding: 11px 15px 12px;
				color: #fff;
				&:focus {
					color: #fff;
				}
				&:hover {
					color: #fff;
					text-decoration: underline;
				}
			}
			&.active a {
				text-decoration: underline;
			}
		}	
	}
	/* header nav */

	/* header search */
	.search {
		display: block;
		height: 34px;
		width: 190px;
		border: 1px solid #e1e1e1;
		background-color: #fff;
		position: absolute;
		top: 3px;
		right: 18px;
		padding-right: 30px;
		box-sizing: border-box;
		input[type="text"] {
			width: 100%;
			height: 100%;
			font-family: $regular;
			font-size: 14px;
			padding: 4px 10px;
			box-sizing: border-box;
			border: none;
			outline: none;
		}
		button[type="submit"] {
			display: block;
			font-family: 'fontAwesome';
			font-size: 16px;
			color: #dddddd;
			background-color: transparent;
			position: absolute;
			right: 5px;
			top: 7px;
			padding: 0;
			border: none;
			outline: none;
			&:after {
				content: '\f002';
			}
		}
	}
	
	/* header search */
	
	@media screen and (max-width: 1199px) {
		position: fixed;
		background-color: #fff;
		padding: 5px 0px;
		box-shadow: 0px 0px 15px rgba(0,0,0,0.5);

		> .container {
			position: relative;
		}
		.down {
			margin: 0 auto;
		}

		.logo {
			.logo__slogan {
				color: #414141;
			}
		}

		.header--col {
			margin: 10px 50px 0 0;
		}

		.phones {
			ul li a {
				color: #414141;
			}
		}

		.nav {
			display: none;
			padding: 0;
			background-color: transparent;
			ul {
				margin-top: 15px;
				border-top: 2px solid #d40005;
				li {
					display: block;
					a {
						padding: 8px 0px;
						color: #414141;
					}
				}
			}
		}
		.nav--btn {
			display: block;
			width: 35px;
			height: 30px;
			position: absolute;
			right: 15px;
			top: 60px;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #d40005;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}


		.search--btn {
			display: block;
			position: absolute;
			top: 10px;
			right: 22px;
			font-family: 'fontAwesome';
			font-size: 24px;
			color: #d40005;
			&:after {
				content: '\f002';

			}
		}
		.search {
			display: none;
			width: calc(100% - 30px);
			right: 15px;
			top: 5px;
		}
	}
	@media screen and (max-width: 768px) {
		.logo {
			display: table;
			margin: 0 auto;
			img {
				width: 70px;
			}
			.logo__slogan {
				font-size: 18px;
				margin-top: 20px;
			}
		}

		.header--col {
			margin: 0;
			.btn {
				display: none;
			}
		}
		
		.phones--btn {
			display: block;
			position: absolute;
			top: 22px;
			right: 50px;
			font-family: 'fontAwesome';
			font-size: 25px;
			color: #d30005;
			&:after {
				content: '\f095';
			}
		}
		.phones {
			display: none;
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			background-color: rgba(255,255,255,0.95);
			padding: 10px;
			margin: 0;
			text-align: center;
			z-index: 1;
			&:after {
				display: none;
			}
		}

		.nav--btn {
			right: auto;
			left: 10px;
			top: 20px;
		}

		.search--btn {
			top: 20px;
			right: 15px;
		}
		.search {
			width: 100%;
			left: 0;
			right: 0;
			z-index: 1;
			input[type="text"] {
				height: 32px;
			}
		}
	}

	@media screen and (max-width: 480px) {
		.logo {
			img {
				display: block;
				width: 50px;
				margin: 0 auto;
			}
			.logo__slogan {
				display: block;
				font-size: 10px;
				margin: 3px 0px;
			}
		}
	}
}
/* header */